import React, { createContext, useContext, useEffect, useReducer, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { useAsyncFn } from 'react-use';
import { Loader } from 'da-frontend-shared-ui-components';
import { useAPI } from '..';
import { fetchProfile } from './actions';
import reducer, { initialState } from './reducer';
import { SessionState, SessionDispatch } from './types';
import { useTranslation } from 'react-i18next';
import config from '~/config';
import { recordPageView } from '~/utils/gtm';
import { 
  AccountTypeRole, 
  compareAccountRolesByAccessLevel, 
  compareAccountTypeRoleByAccessLevel, 
  compareSystemRolesByAccessLevel, 
  Role 
} from 'da-pcc-frontend-shared-domain';

// extend the global window interface to include a userId variable that can
// be accessed by the doorbell script in the root index.html file 
declare global {
  interface Window {
    doorbellUserId: string;
  }
}

const SessionStateContext = createContext<SessionState | undefined>(undefined);
const SessionDispatchContext = createContext<SessionDispatch | undefined>(undefined);

export function SessionProvider({ children }) {
  const [t] = useTranslation();
  const { session } = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useMemo(() => state.profile, [state]);
  
  const [, getMe] = useAsyncFn(async () => {
    dispatch(fetchProfile.started());
    await session
      .getMe()
      .then((profile) => dispatch(fetchProfile.done({ result: profile })))
      .catch((error) => dispatch(fetchProfile.failed({ error })));
  }, [session]);

  useEffect(() => {
    getMe();
  }, [getMe]);

  useEffect(() => {
    if (currentUser && currentUser.id && currentUser.loginType) {
      try {
        // Set a global window userId variable so that it may be accessed by the
        // doorbell script in the root index.html file
        window.doorbellUserId = config.doorbellEnabled ? currentUser.id : undefined;

        // Dynatrace User Tagging (ID & Email)
        if ((window as any).dtrum) {
          let userIdentifier = currentUser.id;
          let userProperties: { [key: string]: string } = { userId: currentUser.id };

          if (currentUser.email) {
            userIdentifier = `${currentUser.id} (${currentUser.email})`;
            userProperties.email = currentUser.email;
          }

          console.log("Dynatrace - Tagging User:", userIdentifier);
          (window as any).dtrum.identifyUser(userIdentifier);
          console.log("Dynatrace - Setting User Properties:", userProperties);
          (window as any).dtrum.setUserProperties(userProperties);
        }
      } catch (error) {
        console.error("Error in Dynatrace user tagging:", error);
      }
  
      const userSystemRole = (currentUser.systemRoles && currentUser.systemRoles.length > 0)
          ? currentUser.systemRoles.sort( compareSystemRolesByAccessLevel )[0].name
          : undefined;

      const userAccountRole = () => {
        let rolesArray: AccountTypeRole[] = [];
    
        if (currentUser.accountSubscriptions && currentUser.accountSubscriptions.length > 0) {
          for (const subscription of currentUser.accountSubscriptions) {
            if (subscription.accountRoles && subscription.accountRoles.length > 0)  {
              let newAccountTypeRole: AccountTypeRole = { 
                type: subscription.accountType, 
                role: subscription.accountRoles.sort( compareAccountRolesByAccessLevel )[0]
              }
              rolesArray.push( newAccountTypeRole );
            }
          }
          return rolesArray.length > 0 ? rolesArray.sort( compareAccountTypeRoleByAccessLevel )[0] : undefined;
        }
        return undefined;
      }

      TagManager.dataLayer({
        dataLayer: {
          userId: currentUser.id,
          userType: (userSystemRole && userSystemRole !== Role.DistributorPortalUser) 
            ? userSystemRole 
            : ((userAccountRole() && userAccountRole().role) 
              ? `ConnectCloud ${userAccountRole().role.name}` 
              : `ConnectCloud Web User`),
          accountType: "Residential",
        },
      });

      recordPageView();
    }
  }, [currentUser]);

  if (state.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={{ getMe }}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  );
}

export function useSessionState() {
  const context = useContext(SessionStateContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
}

export function useSessionDispatch() {
  const context = useContext(SessionDispatchContext);
  if (context === undefined) {
    throw new Error('useSessionDispatch must be used within a SessionProvider');
  }
  return context;
}

export function useSession(): [SessionState, SessionDispatch] {
  return [useSessionState(), useSessionDispatch()];
}
